import axiosApiInstance from "./request"

interface IChatPayload {
  message: string,
  category: string,
  organisation_id: string
}

const chatService = async (payload: IChatPayload) => {
  const response  = { status: true, message: 'Something went wrong. Please try again!', uses: {}, cost: 0 }
  try {
    const res = await axiosApiInstance.post('v2/inquire', payload)
    if(res?.data?.data) {
      response.message = res.data.data.response.trim()
      response.uses = res.data.data.token_usage
      response.cost = res.data.data.cost
    }
  } catch (error) {
    console.error(error)
   
  }
  return response
}

const getAllThreadsService = async (category_id: string) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/all', { category_id: category_id })
    if(response?.data?.data) {
      return response.data.data.response || []
    }
  } catch (error) {
    return []
  }
}

const getThreadData = async (threadId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/data', { thread_id: threadId })
    if(response) {
      return response || []
    }
  } catch (error) {
    return []
  }
}

const speechToText = async (speech_data: any, organisation_id: string) => {
  try {
    const response = await axiosApiInstance.post('v2/voice/speech-to-text', { speech_data: speech_data, organisation_id})
    return response
  } catch (error) {
    return {}
  }
}

const textToSpeech = async (text_data: any, message_id: any, organisation_id: any) => {
  try {
    const response = await axiosApiInstance.post('v2/voice/text-to-speech', { text_data: text_data, message_id: message_id, organisation_id: organisation_id})
    return response
  } catch (error) {
    return {}
  }
}


const updateCollaboration = async (threadId: string, category_id: string, organisation_id: string, collaboration: boolean) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/update/collaboration', { thread_id: threadId, collaboration: collaboration, category_id: category_id, organisation_id: organisation_id })
    return response
  } catch (error) {
    return {
      data: {
        success: 1
      }
    }
  }
}

const setCustomThreadName = async (threadId: any, threadName: string) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/update/name', { thread_id: threadId, thread_name: threadName })
    return response
  } catch (error) {
    return {}
  }
}

const checkIfThreadIsBusy = async (threadId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/data/busy', { thread_id: threadId })
    return response
  } catch (error) {
    return {}
  }
}

const getGeneralThreads = async () => {
  try {
    const response = await axiosApiInstance.post('v2/thread/all', { category_id: 'GENERAL' });
    if (response?.data?.data) {
      return response.data.data.response || [];
    }
  } catch (error) {
    return [];
  }
}

const getThreadLogs = async (threadId: string, organisatinId:string) => {
  try {
    const payload = {
      log_type: 'thread',
      unique_id: threadId,
      organisation_id: organisatinId

    }
    const res = await axiosApiInstance.post('v2/logs/user', payload)
    return res
  } catch (error) {
    console.log(error)
    return []
  }
}

const deleteThreadService = async (threadId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/thread/delete', { thread_id: threadId })
    return response
  } catch (error) {
    return {}
  }
}


const mapMatrixData = (messageAnalyticsData: any) => {
  let completionTotal = 0
  let promptTotal = 0
  let allTotall = 0
  let totalCost = 0
  let uses = []

  for(const analyticsData of messageAnalyticsData) {
    completionTotal = completionTotal + analyticsData.data.usage.completion_tokens
    promptTotal = promptTotal + analyticsData.data.usage.prompt_tokens
    allTotall = allTotall + analyticsData.data.usage.total_tokens
    totalCost = totalCost + analyticsData.data.cost
    uses.push({...analyticsData.data.usage, cost: analyticsData.data.cost, messageId: analyticsData.data.message_id})
  }
  return {
    completionTotal: completionTotal,
    promptTotal: promptTotal,
    allTotall: allTotall,
    totalCost: totalCost,
    uses: uses
  }
}

export {
  chatService,
  mapMatrixData,
  getAllThreadsService,
  getThreadData,
  updateCollaboration,
  checkIfThreadIsBusy,
  getThreadLogs,
  speechToText,
  textToSpeech,
  getGeneralThreads,
  deleteThreadService,
  setCustomThreadName
}
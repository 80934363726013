import axiosApiInstance from "./request"

const getChatCategoryService = async (organisationsId: string) => {
  try {
    const response = await axiosApiInstance.post('v1/category/all', { organisation_id: organisationsId })
    return response?.data?.data?.response || []
  } catch (error) {
    return []
  }
}

const getAllChatCategoryService = async (organisationsId: string) => {
  try {
    const response = await axiosApiInstance.post('v2/category/get', { organisation_id: organisationsId })
    return response?.data?.data || []
  } catch (error) {
    return []
  }
}

const deleteChatCategoryService = async (organisation_id: string, category_id: string) => {
  try {
    const response = await axiosApiInstance.post(
      'v2/category/deleteCategory', 
      { organisation_id: organisation_id, category_id: category_id }
    )
    return response.data.modified_count === 0 ? false : true
  } catch (error) {
    return false
  }
}

const getChatCategoryDataService = async (category_name: string, category_version: string) => {
  try {
    const payload = { category_name: category_name, category_version: category_version }
    const response = await axiosApiInstance.post('v1/category/data', payload)
    return response?.data?.data?.response || []
  } catch (error) {
    return []
  }
}

const addCategoryService = async (payload: any) => {
  try {
    const response = await axiosApiInstance.post('v1/category/create', payload)
    return response?.data?.data?.response || {}
  } catch (error) {
    return null
  }
}

const assignCategoryService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    await axiosApiInstance.post('v1/category/assign', payload)
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const dismissCategoryService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    const res = await axiosApiInstance.post('v1/category/dismiss', payload)
    response.message = res?.data?.data?.response || 'Removed Successfully'
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const createCategoryFromFileService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    const res = await axiosApiInstance.post('v2/category/create', payload)
    response.message = res?.data?.data || 'Created Successfully'
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const editCategoryService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    const res = await axiosApiInstance.post('v2/category/edit', payload)
    response.message = res?.data?.success || 'Updated Successfully'
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const checkGoogleAuth = async (organisation_id: string) => {
  try {
    await axiosApiInstance.post('v2/auth/check/google', {'organisation_id': organisation_id})
    return true
  } catch (error) {
    return false
  }
}

const openPopUp = (url:string) => {
  let height = 600;
  let width = 1020;
  window.open( url, 'popUpWindow', 'height=' + height + ', width=' + width + ', resizable=yes,scrollbars=yes,toolbar=yes' );
}

const getFileListService = async (userId: string, fileType: string, pageToken: string, organisation_id: string ) => {
  try {
    const isAuthenticated = await checkGoogleAuth(organisation_id)
    if(isAuthenticated) {
      const response = await axiosApiInstance.post('v2/category/get-sheets', { file_type: fileType, page_token: pageToken, organisation_id: organisation_id})
      return {
        files: response.data.data.items || [],
        pageToken: response.data.data.page_token,
        authenticated: true
      }
    } else {
      const domain = 'https://accounts.google.com/o/oauth2/auth'
      const response_type = 'code'
      console.log(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID)
      const client_id = `${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`
      const redirect_uri = `${process.env.REACT_APP_API_BASE_URL}v2/auth/verify/google`
      // const scope = 'https://www.googleapis.com/auth/drive.readonly+https://www.googleapis.com/auth/spreadsheets.readonly'
      const scope = 'https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/documents+https://www.googleapis.com/auth/spreadsheets'
      const state = `{'userId': '${userId}', 'organisation_id': '${organisation_id}'}`
      const access_type = 'offline'
      const url = `${domain}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&access_type=${access_type}`
      openPopUp(url)
      return {files: [], pageToken: '', authenticated: false}
    }
  } catch (error) {
    return {files: [], pageToken: '', authenticated: false}
  }
}

const getChatAnalyticsData = async (threadId: string, organisatinId:string) => {
 try {
  const payload = {
    log_type: 'thread',
    unique_id: threadId,
    organisation_id: organisatinId

  }
  const res = await axiosApiInstance.post('v2/logs/user', payload)
  return res?.data?.data || []
 } catch (error) {
  console.log(error)
  return []
 }
}

export {
  getChatCategoryService,
  getChatCategoryDataService,
  addCategoryService,
  assignCategoryService,
  dismissCategoryService,
  createCategoryFromFileService,
  getFileListService,
  getChatAnalyticsData,
  deleteChatCategoryService,
  editCategoryService,
  getAllChatCategoryService
}
import axiosApiInstance from "./request"

const checkGoogleAuth = async (organisation_id: string) => {
    try {
      await axiosApiInstance.post('v2/auth/check/google', {'organisation_id': organisation_id})
      return true
    } catch (error) {
      return false
    }
  }


const disconnectGoogleAuth = async (organisation_id: string) => {
    try {
      await axiosApiInstance.post('v2/auth/check/google/disconnect', {'organisation_id': organisation_id})
      return true
    } catch (error) {
      return false
    }
  }


const openPopUp = (url:string) => {
    let height = 600;
    let width = 1020;
    window.open( url, 'popUpWindow', 'height=' + height + ', width=' + width + ', resizable=yes,scrollbars=yes,toolbar=yes' );
  }

  const connectWithGoogle = async (userId: string, organisation_id: string ) => {
    try {
        const domain = 'https://accounts.google.com/o/oauth2/auth'
        const response_type = 'code'
        const client_id = `${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`
        const redirect_uri = `${process.env.REACT_APP_API_BASE_URL}v2/auth/verify/google`
       const scope = 'https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/documents+https://www.googleapis.com/auth/spreadsheets'
        const state = `{'userId': '${userId}', 'organisation_id': '${organisation_id}'}`
        const access_type = 'offline'
        const url = `${domain}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&access_type=${access_type}`
        openPopUp(url)
        return null
    } catch (error) {
      return null
    }
  }

export {
    checkGoogleAuth,
    disconnectGoogleAuth,
    connectWithGoogle,
    openPopUp
}
import axiosApiInstance from "./request"

const getDatabasesService = async (organisationsId: string, page: any, pageSize: any, sortField: any, sortOrder: any) => {
  try {
    const response = await axiosApiInstance.post('v2/database/all', { organisation_id: organisationsId, page: page, page_size: pageSize, sort_field: sortField, sort_order: sortOrder })
    return response?.data?.data || []
  } catch (error) {
    return []
  }
}

const getDatabaseService = async (category_name: string, category_version: string) => {
  try {
    const payload = { category_name: category_name, category_version: category_version }
    const response = await axiosApiInstance.post('v1/databases/data', payload)
    return response?.data?.data?.response || []
  } catch (error) {
    return []
  }
}

const addDatabaseService = async (payload: any) => {
  try {
    const response = await axiosApiInstance.post('v1/database/create', payload)
    return response?.data?.data?.response || {}
  } catch (error) {
    return null
  }
}

const createDatabaseFromFileService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    const res = await axiosApiInstance.post('v2/database/create', payload)
    response.message = res?.data?.data || 'Created Successfully'
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const createThreadDatabaseFromFileService = async (payload: any) => {
  const response = { status: true, message: ''}
  try {
    const res = await axiosApiInstance.post('v2/database/create/thread', payload)
    response.message = res?.data?.data || 'Created Successfully'
  } catch (error:any) {
    response.message = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.status = false
  }
  return response
}

const get_upload_url = async (organisation_id: string, database_id: string, version: any, file_type: any, file_id: any) => {
  const response = { upload_url_status: true, upload_url: ''}
  try {
    const res = await axiosApiInstance.post('v2/database/create/get_upload_url', { organisation_id: organisation_id, database_id: database_id, version: version, file_type: file_type, file_id: file_id})
    response.upload_url = res?.data?.data || 'Created Successfully'
  } catch (error: any) {
    response.upload_url = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.upload_url_status = false
  }
  return response
}

const get_upload_url_for_thread = async (organisation_id: string, thread_id: string, version: any, file_type: any, file_id: any) => {
  const response = { upload_url_status: true, upload_url: ''}
  try {
    const res = await axiosApiInstance.post('v2/database/create/get_upload_url/thread', { organisation_id: organisation_id, thread_id: thread_id, file_type: file_type, file_id: file_id})
    response.upload_url = res?.data?.data || 'Created Successfully'
  } catch (error: any) {
    response.upload_url = error?.response?.data?.data?.message || 'Something went wrong. please try again!'
    response.upload_url_status = false
  }
  return response
}

const deleteDatabaseService = async (database_id: string, organisation_id: string) => {
  try {
    const response = await axiosApiInstance.post('v2/database/delete', { database_id: database_id, organisation_id: organisation_id })
    console.log("Delete Response", response)
    return {success: response?.data?.success ?? 0, 'message': response?.data?.data} || {}
  } catch (error: any) {
    console.log("Delete Error", error)
    return {success: 0, 'message': error?.response?.data?.data?.message || 'Something went wrong. please try again!'}
  }
}

const downloadAudioFileService = async (file_name: any, org_id: any) => {
  try {
    const response = await axiosApiInstance.post('v2/voice/text-to-speech/download', { file_name: file_name, organisation_id: org_id})
    return response
  } catch (error) {
    return {}
  }
}

const deleteFileService = async (file_name: any, org_id: any) => {
  try {
    const response = await axiosApiInstance.post('v2/voice/text-to-speech/delete', { file_name: file_name, organisation_id: org_id})
    return {success: response?.data?.success ?? 0, 'message': response?.data?.message} || {}
  } catch (error: any) {
    return {success: 0, 'message': error?.response?.data?.data?.message || 'Something went wrong. please try again!'}
  }
}

const getFilesListService = async (org_id: any) => {
  try {
    const response = await axiosApiInstance.post('v2/voice/text-to-speech/list', { organisation_id: org_id})
    return response
  } catch (error) {
    return {}
  }
}


const editDatabaseNameService = async (database_id: string, database_name: string, organisation_id: string, database_version: number) => {
  try {
    const response = await axiosApiInstance.post('v2/database/edit', { database_id: database_id, database_name: database_name, organisation_id: organisation_id, database_version: database_version})
    console.log("Update Response", response)
    return response?.data?.success || {}
  } catch (error) {
    return null
  }
}

const checkGoogleAuth = async (organisation_id: string) => {
  try {
    await axiosApiInstance.post('v2/auth/check/google', {'organisation_id': organisation_id})
    return true
  } catch (error) {
    return false
  }
}

const getGoogleOAuthToken = async (organisation_id: string) => {
  try {
    const response = await axiosApiInstance.post('v2/auth/check/google/get-auth-token', {'organisation_id': organisation_id})
    return response?.data?.token || null
  } catch (error) {
    return false
  }
}

// const getAuthToken

const openPopUp = (url:string) => {
  let height = 600;
  let width = 1020;
  window.open( url, 'popUpWindow', 'height=' + height + ', width=' + width + ', resizable=yes,scrollbars=yes,toolbar=yes' );
}

const getGoogleAuthToken = async (userId: string, organisation_id: string ) => {
  try {
    const isAuthenticated = await checkGoogleAuth(organisation_id)
    console.log("isAuthenticated", isAuthenticated)
    if(isAuthenticated) {
      const response = await getGoogleOAuthToken(organisation_id)
      return response
    } else {
      const domain = 'https://accounts.google.com/o/oauth2/auth'
      const response_type = 'code'
      // console.log("process.env", process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID)
      const client_id = `${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`
      console.log("client_id", client_id)
      const redirect_uri = `${process.env.REACT_APP_API_BASE_URL}v2/auth/verify/google`
      // const scope = 'https://www.googleapis.com/auth/drive.readonly+https://www.googleapis.com/auth/spreadsheets.readonly'
     const scope = 'https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/documents+https://www.googleapis.com/auth/spreadsheets'
      const state = `{'userId': '${userId}', 'organisation_id': '${organisation_id}'}`
      const access_type = 'offline'
      const url = `${domain}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&access_type=${access_type}`
      openPopUp(url)
      return null
    }
  } catch (error) {
    return null
  }
}

const getFileListService = async (userId: string, fileType: string, pageToken: string, organisation_id: string ) => {
  try {
    const isAuthenticated = await checkGoogleAuth(organisation_id)
    if(isAuthenticated) {
      const response = await axiosApiInstance.post('v2/category/get-sheets', { file_type: fileType, page_token: pageToken, organisation_id: organisation_id})
      return {
        files: response.data.data.items || [],
        pageToken: response.data.data.page_token,
        authenticated: true
      }
    } else {
      const domain = 'https://accounts.google.com/o/oauth2/auth'
      const response_type = 'code'
      // console.log("process.env", process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID)
      const client_id = `${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`
      const redirect_uri = `${process.env.REACT_APP_API_BASE_URL}v2/auth/verify/google?organisation_id=${organisation_id}`
      // const scope = 'https://www.googleapis.com/auth/drive.readonly+https://www.googleapis.com/auth/spreadsheets.readonly'
      const scope = 'https://www.googleapis.com/auth/drive.file+https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/documents+https://www.googleapis.com/auth/spreadsheets'
      const state = userId
      const access_type = 'offline'
      const url = `${domain}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&access_type=${access_type}`
      openPopUp(url)
      return {files: [], pageToken: '', authenticated: false}
    }
  } catch (error) {
    return {files: [], pageToken: '', authenticated: false}
  }
}

const getChatAnalyticsData = async (threadId: string, organisatinId:string) => {
 try {
  const payload = {
    log_type: 'thread',
    unique_id: threadId,
    organisation_id: organisatinId

  }
  const res = await axiosApiInstance.post('v2/logs/user', payload)
  return res?.data?.data || []
 } catch (error) {
  console.log(error)
  return []
 }
}

export {
  getDatabasesService,
  getDatabaseService,
  addDatabaseService,
  createDatabaseFromFileService,
  getFileListService,
  getChatAnalyticsData,
  deleteDatabaseService,
  editDatabaseNameService,
  getGoogleAuthToken,
  deleteFileService,
  getFilesListService,
  downloadAudioFileService,
  get_upload_url,
  get_upload_url_for_thread,
  createThreadDatabaseFromFileService
}
import React, { useMemo, useState } from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { CgUserList } from "react-icons/cg";
import { HiUserAdd } from "react-icons/hi";
import ChatCategoryAssign from "./ChatCategoryAssign";
import ChatCategoryUsers from "./ChatCategoryUsers";
import {
  deleteChatCategoryService,
  getAllChatCategoryService,
} from "../../services/category.service";
import {
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableSortLabel,
  Pagination,
  Chip,
  Modal,
  Typography,
  Tooltip,
} from '@mui/material';
import ChatContentForm from "./ChatContentForm";

const ChatCategoryList: React.FC<any> = () => {
  const { state, dispatch }: any = useApp();
  const { userChatCategories }: any = state;

  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [categoryToDelete, setCategoryToDelete]: any = useState(null);
  const [pagination, setPagination]: any = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sortField, setSortField]: any = useState('category_name');
  const [sortOrder, setSortOrder]: any = useState('asc');

  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState('');
  const [modalContent, setModalContent]: any = useState(null);

  const deleteChatCategory = (category: any): void => {
    setCategoryToDelete(category);
    setIsDialogOpen(true);
  };

  const handleConfirm = async (): Promise<void> => {
    if (!categoryToDelete) return;
    const isDeleted: any = await deleteChatCategoryService(
      state.selectedOrganisation._id,
      categoryToDelete.category_id
    );
    if (isDeleted) {
      setStatus('Deleted Successfully', 'success');
      const categories: any = await getAllChatCategoryService(
        state.selectedOrganisation._id
      );
      dispatch({
        type: 'SET_USER_CHAT_CATEGORIES',
        payload: categories || [],
      });
      if (categories.length > 0) {
        dispatch({
          type: 'SET_USER_ACTIVE_CHAT_CATEGORY',
          payload: categories[0] || {},
        });
      }
    } else {
      setStatus('Deletion failed!', 'error');
    }
    setIsDialogOpen(false);
  };

  const handleCancel = (): void => {
    setIsDialogOpen(false);
  };

  const setStatus = (message: any, type: any): void => {
    dispatch({
      type: 'SET_NOTIFICATION',
      payload: { show: true, title: 'Chat Topic delete', message, type },
    });
  };

  const handleSort = (field: any): void => {
    const isAsc: boolean = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const sortedData: any = useMemo(() => {
    if (!userChatCategories) return [];
    let sorted: any = [...userChatCategories];
    if (sortField) {
      sorted.sort((a: any, b: any) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [userChatCategories, sortField, sortOrder]);

  const paginatedData: any = useMemo(() => {
    const startIndex: number = pagination.pageIndex * pagination.pageSize;
    const endIndex: number = startIndex + pagination.pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, pagination.pageIndex, pagination.pageSize]);

  const handlePaginationChange = (event: any, value: any): void => {
    setPagination({ ...pagination, pageIndex: value - 1 });
  };

  const columns: any = [
    {
      accessorKey: 'category_name',
      header: 'Chat Topic Name',
      cell: (row: any): any => (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.category_name}
        </Typography>
      ),
    },
    {
      accessorKey: 'versions',
      header: 'Version',
      cell: (row: any): any => (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row?.versions?.length ?? 'N/A'}
        </Typography>
      ),
    },
    {
      accessorKey: 'drives',
      header: 'Drives',
      cell: (row: any): any => {
        let databases: any = state.databases || [];
        const databasesOfCategory: any = row.databaseIds || [];
        databases = databases.filter((db: any) =>
          databasesOfCategory.includes(db.database_id)
        );
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              maxWidth: '200px',
              overflow: 'hidden',
            }}
          >
            {databases.map((db: any) => (
              <Tooltip title={db.database_name} key={db.database_id}>
                <Chip
                  label={db.database_name}
                  sx={{
                    maxWidth: '150px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Tooltip>
            ))}
          </Box>
        );
      },
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: (row: any): any => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <Button
            onClick={() =>
              openModalWithContent(
                'Update Chat Topic',
                <ChatContentForm
                  initialCategoryId={row.category_id}
                  initialCategoryInstruction={row.category_instruction}
                  initialDatabaseIds={row.databaseIds}
                  initialCategoryName={row.category_name}
                />
              )
            }
            color="primary"
          >
            <MdOutlineEdit size={20} />
          </Button>
          <Button onClick={() => deleteChatCategory(row)} color="secondary">
            <MdOutlineDelete size={20} />
          </Button>
          <Button
            onClick={() =>
              openModalWithContent(
                'Chat Topic Users',
                <ChatCategoryUsers category={row} />
              )
            }
          >
            <CgUserList size={20} />
          </Button>
          <Button
            onClick={() =>
              openModalWithContent(
                'Assign Chat Topic',
                <ChatCategoryAssign category={row} />
              )
            }
          >
            <HiUserAdd size={20} />
          </Button>
        </Box>
      ),
    },
  ];

  const openModalWithContent = (title: any, content: any): void => {
    setModalTitle(title);
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col: any, index: any) => (
                <TableCell
                  key={col.accessorKey}
                  sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    borderRight:
                      index < columns.length - 1
                        ? '1px solid rgba(224, 224, 224, 1)'
                        : undefined,
                  }}
                  sortDirection={
                    sortField === col.accessorKey ? sortOrder : false
                  }
                >
                  {col.accessorKey !== 'actions' ? (
                    <TableSortLabel
                      active={sortField === col.accessorKey}
                      direction={
                        sortField === col.accessorKey ? sortOrder : 'asc'
                      }
                      onClick={() => handleSort(col.accessorKey)}
                    >
                      {col.header}
                    </TableSortLabel>
                  ) : (
                    col.header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row: any, idx: any) => (
              <TableRow key={idx} hover>
                {columns.map((col: any, colIndex: any) => (
                  <TableCell
                    key={col.accessorKey}
                    sx={{
                      textAlign: col.accessorKey === 'actions' ? 'center' : 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      borderRight:
                        colIndex < columns.length - 1
                          ? '1px solid rgba(224, 224, 224, 1)'
                          : undefined,
                    }}
                  >
                    {col.cell ? col.cell(row) : row[col.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(userChatCategories?.length / pagination.pageSize)}
          page={pagination.pageIndex + 1}
          onChange={handlePaginationChange}
        />
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: '70%',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatCategoryList;

import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from "../../hooks";
import { MdOutlineDelete, MdOutlineEdit, MdRefresh } from "react-icons/md";
import {
  createDatabaseFromFileService,
  deleteDatabaseService,
  editDatabaseNameService,
  getDatabasesService,
} from "../../services/databases.service";
import EditDatabaseDialog from "./EditDatabaseModal";
import Spinner from "../shared/Spinner";
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import {
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableSortLabel,
  Pagination,
  Modal,
  Typography,
} from "@mui/material";

const queryClient: any = new QueryClient();

const fetchDatabases = async (orgId: any): Promise<any> => {
  const response: any = await getDatabasesService(
    orgId,
    null,
    null,
    null,
    null
  );
  return response;
};

const DatabaseList: React.FC<any> = ({ setTabs }: any) => {
  const { state, dispatch }: any = useApp();
  const [loadingMap, setLoadingMap]: any = useState({});
  const [isDialogOpen, setIsDialogOpen]: any = useState(false);
  const [databaseToDelete, setDatabaseToDelete]: any = useState(null);
  const [editDatabaseData, setEditDatabaseData]: any = useState(null);
  const [pagination, setPagination]: any = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sortField, setSortField]: any = useState("created_at");
  const [sortOrder, setSortOrder]: any = useState("asc");

  const [openModal, setOpenModal]: any = useState(false);
  const [modalTitle, setModalTitle]: any = useState("");
  const [modalContent, setModalContent]: any = useState(null);

  const setStatus = (message: any, type: any): void => {
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Drive Service",
        message: message,
        type: type,
      },
    });
  };

  const { data, isFetching }: any = useQuery({
    queryKey: ["databases", state.selectedOrganisation._id],
    queryFn: () => fetchDatabases(state.selectedOrganisation._id),
  });

  useEffect((): void => {
    if (data) {
      dispatch({ type: "SET_DATABASES", payload: data.response });
    }
  }, [data, dispatch]);

  const handleOpenDialog = (database: any): void => {
    setDatabaseToDelete(database);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    setDatabaseToDelete(null);
  };

  const handleDeleteDatabase = async (): Promise<void> => {
    if (databaseToDelete) {
      await handleDatabaseDeleteConfirm(databaseToDelete.database_id);
      handleCloseDialog();
    }
  };

  const handleDatabaseDeleteConfirm = async (database_id: any): Promise<void> => {
    const { success, message }: any = await deleteDatabaseService(
      database_id,
      state.selectedOrganisation._id
    );
    if (success === 1) {
      setStatus("Drive deleted successfully", "success");
      const databases: any = await getDatabasesService(
        state.selectedOrganisation._id,
        null,
        null,
        null,
        null
      );
      dispatch({ type: "SET_DATABASES", payload: databases.response });
    } else {
      setStatus(message, "error");
    }
  };

  const handleOpenEditDialog = (database: any): void => {
    setEditDatabaseData(database);
    setModalTitle("Edit Database");
    setModalContent(
      <EditDatabaseDialog
        initialUsedVersion={database.version_in_use}
        initialVersion={database.version}
        databaseId={database.database_id}
        initialName={database.database_name}
        versions={database.versions}
        onConfirm={handleDatabaseUpdateConfirm}
        onCancel={handleCloseModal}
      />
    );
    setOpenModal(true);
  };

  const handlePaginationChange = (event: any, value: any): void => {
    setPagination({ pageIndex: value - 1, pageSize: 10 }); // Adjusted for zero-based index
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    setEditDatabaseData(null);
  };

  const handleDatabaseUpdateConfirm = async (
    database_id: any,
    database_name: any,
    database_version: any
  ): Promise<void> => {
    const isUpdated: any = await editDatabaseNameService(
      database_id,
      database_name,
      state.selectedOrganisation._id,
      database_version
    );
    if (isUpdated) {
      setStatus("Drive has been successfully updated.", "success");
      const databases: any = await getDatabasesService(
        state.selectedOrganisation._id,
        null,
        null,
        null,
        null
      );
      dispatch({ type: "SET_DATABASES", payload: databases.response });
    } else {
      setStatus("Drive update failed!", "error");
    }
    handleCloseModal();
  };

  const handleRefresh = async (database: any): Promise<void> => {
    setLoadingMap({ ...loadingMap, [database.database_id]: true });
    dispatch({
      type: "SET_NOTIFICATION",
      payload: {
        show: true,
        title: "Drive Service",
        message: "The drive is being synced. Please wait...",
        type: "progress",
        toastId: database.database_id,
      },
    });
    try {
      const payload: any = {
        database_name: database.database_name,
        organisation_id: state.selectedOrganisation._id,
        is_file_upload: true,
        database_id: database.database_id,
        version: database?.version ?? null,
        file_ids: database?.file_ids ?? [],
        is_google_drive_file: database?.is_google_drive_file ?? false,
      };
      const { status }: any = await createDatabaseFromFileService(payload);
      if (status) {
        const databases: any = await getDatabasesService(
          state.selectedOrganisation._id,
          null,
          null,
          null,
          null
        );
        dispatch({ type: "SET_DATABASES", payload: databases.response });
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Drive has been successfully synchronised",
            type: "update",
            toastId: database.database_id,
            updateType: "success",
          },
        });
      } else {
        dispatch({
          type: "SET_NOTIFICATION",
          payload: {
            show: true,
            title: "Drive Service",
            message: "Unable to synchronise drive",
            type: "update",
            toastId: database.database_id,
            updateType: "error",
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          show: true,
          title: "Drive Service",
          message: "Unable to synchronise drive",
          type: "update",
          toastId: database.database_id,
          updateType: "error",
        },
      });
    } finally {
      setLoadingMap({ ...loadingMap, [database.database_id]: false });
    }
  };

  const handleSort = (field: any): void => {
    const isAsc: boolean = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const sortedData: any = useMemo(() => {
    if (!state.databases) return [];
    let sorted: any = [...state.databases];
    if (sortField) {
      sorted.sort((a: any, b: any) => {
        if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [state.databases, sortField, sortOrder]);

  const paginatedData: any = useMemo(() => {
    const startIndex: number = pagination.pageIndex * pagination.pageSize;
    const endIndex: number = startIndex + pagination.pageSize;
    return sortedData.slice(startIndex, endIndex);
  }, [sortedData, pagination.pageIndex, pagination.pageSize]);

  const columns: any = useMemo(
    () => [
      {
        accessorKey: "database_name",
        header: "Drive Name",
        cell: ({ row }: any) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row.database_name}
          </Typography>
        ),
      },
      {
        accessorKey: "version_in_use",
        header: "Version",
        cell: ({ row }: any) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row.version_in_use ?? "N/A"}
          </Typography>
        ),
      },
      {
        accessorKey: "created_at",
        header: "Created At",
        cell: ({ row }: any) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {new Date(row.created_at).toLocaleString()}
          </Typography>
        ),
      },
      {
        accessorKey: "updated_at",
        header: "Updated At",
        cell: ({ row }: any) => (
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {new Date(row.updated_at).toLocaleString()}
          </Typography>
        ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        cell: ({ row }: any) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            {row?.is_google_drive_file && (
              <>
                {loadingMap[row.database_id] ? (
                  <Spinner />
                ) : (
                  <Button onClick={() => handleRefresh(row)} color="primary">
                    <MdRefresh size={20} />
                  </Button>
                )}
              </>
            )}
            <Button onClick={() => handleOpenEditDialog(row)} color="primary">
              <MdOutlineEdit size={20} />
            </Button>
            <Button onClick={() => handleOpenDialog(row)} color="secondary">
              <MdOutlineDelete size={20} />
            </Button>
          </div>
        ),
      },
    ],
    [loadingMap]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((col: any, index: any) => (
                <TableCell
                  key={col.accessorKey}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderRight:
                      index < columns.length - 1
                        ? "1px solid rgba(224, 224, 224, 1)"
                        : undefined,
                  }}
                  sortDirection={
                    sortField === col.accessorKey ? sortOrder : false
                  }
                >
                  {col.accessorKey !== "actions" ? (
                    <TableSortLabel
                      active={sortField === col.accessorKey}
                      direction={
                        sortField === col.accessorKey ? sortOrder : "asc"
                      }
                      onClick={() => handleSort(col.accessorKey)}
                    >
                      {col.header}
                    </TableSortLabel>
                  ) : (
                    col.header
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row: any, rowIndex: any) => (
                <TableRow key={rowIndex} hover>
                  {columns.map((col: any, colIndex: any) => (
                    <TableCell
                      key={col.accessorKey}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign:
                          col.accessorKey === "actions" ? "center" : "left",
                        borderRight:
                          colIndex < columns.length - 1
                            ? "1px solid rgba(224, 224, 224, 1)"
                            : undefined,
                      }}
                    >
                      {col.cell ? col.cell({ row }) : row[col.accessorKey]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={Math.ceil(sortedData.length / pagination.pageSize)}
          page={pagination.pageIndex + 1}
          onChange={handlePaginationChange}
        />
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this drive? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteDatabase} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "70%",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 }}>
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

const DatabaseListWithProvider: React.FC<any> = () => (
  <QueryClientProvider client={queryClient}>
    <DatabaseList />
  </QueryClientProvider>
);

export default DatabaseListWithProvider;
